<template>
    <div class="d-flex align-items-center text-nowrap">
        <template v-if="columnData.indexOf('tracker-startup') >= 0">
            <div class="card flex-row">
                <b-dropdown class="ml-5 drag-n-drop icons-list dropdown-simple dropdown-small" boundary="viewport">
                    <b-dropdown-item class="icons-list__pdf" @click="$emit('action', {action: 'pdf', rowId: rowId})">
                        Export PDF
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </template>

        <template v-if="columnData.indexOf('ecosystem-startup') >= 0">
            <div class="card flex-row">
                <b-dropdown class="ml-5 drag-n-drop icons-list dropdown-simple dropdown-small" boundary="viewport">
                    <b-dropdown-item class="icons-list__delete" @click="$emit('action', {action: 'delete', rowId: rowId})">
                        Remove Startup
                    </b-dropdown-item>
                    <b-dropdown-item class="icons-list__delete" @click="$emit('action', {action: 'add-to-portfolio', rowId: rowId})">
                        Add to a portfolio
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </template>

        <template v-else-if="columnData.indexOf('tracker-submission') >= 0">
            <a class="link" @click="$emit('action', {action: 'view-answers', rowId: rowId})">View Answers</a>
        </template>

        <template v-else-if="columnData.indexOf('portfolio-startups') >= 0">
            <div class="card flex-row">
                <b-dropdown class="ml-5 drag-n-drop icons-list dropdown-simple dropdown-small" boundary="viewport">
                    <b-dropdown-item class="" @click="$emit('action', {action: 'add-to-tracker', rowId: rowId})">
                        Add to tracker
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </template>

        <template v-else-if="columnData.indexOf('task') >= 0">
            <div class="drag-n-drop-wrapper"> <!--flex-row ml-auto-->
                <b-dropdown class="dropdown-original dropdown-original--small icons-list dropdown-small" boundary="viewport">
                    <b-dropdown-item class="icons-list__edit" @click="$emit('action', {action: 'edit', rowId: rowId})">
                        Edit Task
                    </b-dropdown-item>
                    <b-dropdown-item class="icons-list__delete" @click="$emit('action', {action: 'delete', rowId: rowId})">
                        Remove Task
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </template>

        <template v-else>
            <div v-if="columnData.indexOf('edit') >= 0">
                <a @click="$emit('action', {action: 'edit', rowId: rowId})" class="link-edit link-edit--secondary">Edit</a>
            </div>

            <div v-if="columnData.indexOf('relations') >= 0">
                <a @click="$emit('action', {action: 'relations', rowId: rowId})" class="link-relations ml-2">Relations</a>
            </div>

            <div v-if="columnData.indexOf('sync-cb') >= 0">
                <a @click="$emit('action', {action: 'sync-cb', rowId: rowId})" class="link-sync ml-2">Sync CB</a>
            </div>

            <div v-if="columnData.indexOf('sync-kg') >= 0">
                <a @click="$emit('action', {action: 'sync-kg', rowId: rowId})" class="link-sync ml-2">Sync KG</a>
            </div>

            <div v-if="columnData.indexOf('sync-ppx') >= 0">
                <a @click="$emit('action', {action: 'sync-ppx', rowId: rowId})" class="link-sync ml-2">Sync PPX</a>
            </div>

            <div v-if="columnData.indexOf('sync-elastic') >= 0">
                <a @click="$emit('action', {action: 'sync-elastic', rowId: rowId})" class="link-sync ml-2">Sync Elastic</a>
            </div>

            <div v-if="columnData.indexOf('delete') >= 0">
              <a @click="$emit('action', {action: 'delete', rowId: rowId})" class="link-delete link-delete--text ml-4 w-auto">
                <svg width="18px" height="18px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <title>Actions/Delete</title>
                    <g id="Actions/Delete" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g id="Group" transform="translate(2, 1)">
                            <path d="M10.5,4.5 L10.5,12 C10.5,12.4142136 10.3321068,12.7892136 10.0606602,13.0606602 C9.78921356,13.3321068 9.41421356,13.5 9,13.5 L3,13.5 C2.58578644,13.5 2.21078644,13.3321068 1.93933983,13.0606602 C1.66789322,12.7892136 1.5,12.4142136 1.5,12 L1.5,4.5 L10.5,4.5 Z" id="Rectangle" stroke="#0083B2"></path>
                            <rect id="Rectangle" fill="#0083B2" x="3.5" y="6" width="1" height="6" rx="0.5"></rect>
                            <rect id="Rectangle-Copy" fill="#0083B2" x="5.5" y="6" width="1" height="6" rx="0.5"></rect>
                            <rect id="Rectangle" stroke="#0083B2" x="0.5" y="2.5" width="11" height="2" rx="1"></rect>
                            <path d="M7.22930937,4.4408921e-16 C7.9625681,2.22044605e-16 8.58835326,0.530119564 8.70890025,1.25340152 L8.8059335,1.83560101 C8.89672856,2.38037133 8.52870889,2.89559887 7.98393857,2.98639392 C7.92960714,2.99544916 7.87462044,3 7.81953958,3 L4.18046042,3 C3.62817567,3 3.18046042,2.55228475 3.18046042,2 C3.18046042,1.94491914 3.18501126,1.88993244 3.1940665,1.83560101 L3.29109975,1.25340152 C3.41164674,0.530119564 4.0374319,2.22044605e-16 4.77069063,2.22044605e-16 L7.22930937,4.4408921e-16 Z M7.23500017,1 L4.75474202,1 C4.51799395,1 4.3136973,1.16604011 4.26530065,1.39778871 L4.13953933,2 L7.83409133,2 L7.72693736,1.41056957 C7.68371603,1.17281851 7.47664795,1 7.23500017,1 Z" id="Combined-Shape" fill="#0083B2"></path>
                            <rect id="Rectangle-Copy-2" fill="#0083B2" x="7.5" y="6" width="1" height="6" rx="0.5"></rect>
                        </g>
                    </g>
                </svg>Delete
              </a>
            </div>

            <div v-if="columnData.indexOf('merge') >= 0">
                <a @click="$emit('action', {action: 'merge', rowId: rowId})" class="link ml-2">Merge</a>
            </div>

            <div v-if="columnData.indexOf('share-portfolio') >= 0">
                <a @click="$emit('action', {action: 'share', rowId: rowId})" class="link-share ml-3">Share Portfolio</a>
            </div>

            <div v-if="columnData.indexOf('connect') >= 0">
                <a @click="$emit('action', {action: 'connect', rowId: rowId})" class="btn btn-primary">Connect</a>
            </div>

            <div v-if="columnData.indexOf('edit-funding-rounds') >= 0">
                <a @click="$emit('action', {action: 'edit-funding-rounds', rowId: rowId})" class="link-edit link-edit--secondary ml-2">Edit Funding Rounds</a>
            </div>

            <div v-if="columnData.indexOf('crawl') >= 0">
                <a @click="$emit('action', {action: 'crawl', rowId: rowId})" class="link-sync ml-2">Crawl</a>
            </div>

            <div v-if="columnData.indexOf('check-job-status') >= 0">
                <a @click="$emit('action', {action: 'check-job-status', rowId: rowId})" class="link-sync ml-2">Check job status</a>
            </div>

            <div v-if="columnData.indexOf('remove-job') >= 0">
                <a @click="$emit('action', {action: 'remove-job', rowId: rowId})" class="link-delete link-delete--text w-auto ml-2"><font-awesome-icon :icon="['fa', 'trash']" />Remove job</a>
            </div>

            <div v-if="columnData.indexOf('download-crawl-json') >= 0">
                <a @click="$emit('action', {action: 'download-crawl-json', rowId: rowId})" class="ml-2">Download JSON</a>
            </div>

            <div v-if="columnData.indexOf('show-crawled-data') >= 0">
                <a @click="$emit('action', {action: 'show-crawled-data', rowId: rowId})" class="ml-2">Crawled Data</a>
            </div>

            <div v-if="columnData.indexOf('translate') >= 0">
                <a @click="$emit('action', {action: 'translate', rowId: rowId})" class="ml-2">Translate</a>
            </div>

            <div v-if="columnData.indexOf('users') >= 0">
                <a @click="$emit('action', {action: 'users', rowId: rowId})" class="link-edit link-edit--secondary ml-2">Users</a>
            </div>

            <div v-if="columnData.indexOf('open-as-user') >= 0">
                <a @click="$emit('action', {action: 'users', rowId: rowId})" class="link-share link-edit--secondary ml-2">Open as User</a>
            </div>

            <div v-if="columnData.indexOf('invite') >= 0">
                <a @click="$emit('action', {action: 'invite', rowId: rowId})" class="link-invite ml-2 w-auto"><font-awesome-icon :icon="['fa', 'user-plus']" />Invite</a>
            </div>

            <div v-if="columnData.indexOf('add-hours-spent') >= 0">
                <a @click="$emit('action', {action: 'add-hours-spent', rowId: rowId})" class="ml-2">Log time spent</a>
            </div>

            <div v-if="columnData.indexOf('add-hours') >= 0">
                <a @click="$emit('action', {action: 'add-hours', rowId: rowId})" class="ml-2">Add hours</a>
            </div>

            <div v-if="columnData.indexOf('contacts') >= 0">
                <a @click="$emit('action', {action: 'contacts', rowId: rowId})" class="link-relations ml-2">Contacts</a>
            </div>

            <div v-if="columnData.indexOf('map-categories') >= 0">
                <a @click="$emit('action', {action: 'map-categories', rowId: rowId})" class="link-relations ml-2">Map Categories</a>
            </div>

            <div v-if="columnData.includes('admin-export-enterprises')">
                <b-dropdown text="Export" class="dropdown--export">
                    <b-dropdown-item @click="$emit('action', {action: 'export-custom-values', rowId: rowId})">
                        Export custom values
                    </b-dropdown-item>

                    <b-dropdown-item @click="$emit('action', {action: 'export-startups-stages', rowId: rowId})">
                        Export startups stages
                    </b-dropdown-item>
                </b-dropdown>
            </div>

            <div v-for="(action, index) in columnData" :key="index">
                <template v-if="typeof(action) === 'object'">
                    <a
                        class="mr-2"
                        :class="action.class"
                        @click="$emit('action', {action: action.name, rowId: rowId})"
                    >
                        {{action.label}}
                    </a>
                </template>
            </div>

        </template>
    </div>
</template>

<script>
export default {
    props: {
        rowId: Number,
        columnData: Array,
    },
}
</script>

<style scoped>

</style>
